import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../components/layout"
import { apps, app, downloadButton } from './app.module.css';
import { StaticImage } from "gatsby-plugin-image";

// markup
const AppPage = ({ data }: { data: AllAppversions }) => {
  const fontSize = '2rem';

  return (
    <Layout pageTitle="Apotheken Direkt App zum Download">
      <StaticImage src="../../static/icon.png" alt="App Icon" style={{ borderRadius: 20}} />
      <div className={apps}>
      {data.allAppversionsJson.edges.map((edge) => (
        <div key={edge.node.os + edge.node.version} className={app}>
          <div>
            <h4>{edge.node.os === "android" ? "Android" : "iOS"} Version {edge.node.version}{edge.node.latest && <span style={{ fontStyle: "italic"}}> - neuste Version</span>}</h4>
            {edge.node.os === "android" ? <p><a className={downloadButton} href={edge.node.link} download target="_blank">Herunterladen für Android</a></p> : <p><a className={downloadButton} href={edge.node.link} target="_blank">Zum App Store</a></p>}
          </div>
        </div>
      ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AllAppversions {
    allAppversionsJson(sort: { fields: os, order: ASC }) {
      edges {
        node {
          version
          latest
          link
          os
        }
      }
    }
  }
`;

export default AppPage
