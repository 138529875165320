import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { container } from './layout.module.css';

const Layout = ({ pageTitle, children }: { pageTitle?: string, children: any }) => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel='icon' type='image/png' href='/favicon.png' sizes='144x144' />
        {pageTitle ? (<title>Apotheken Direkt - {pageTitle}</title>) : ((<title>Apotheken Direkt</title>))}
      </Helmet>
      <main className={container}>
        <header>
          <h1>Apotheken Direkt</h1>
          {pageTitle && (<h2>{pageTitle}</h2>)}
        </header>
        <article>
          {children}
        </article>
        <footer>
          (c) Apotheken Direkt - Engel Apotheke Darmstadt, 2025 - <Link to='/impressum'>Impressum</Link> - <Link to='/agb'>AGB</Link> - <Link to='/datenschutz'>Datenschutzerklärung</Link>
        </footer>
      </main>
    </React.Fragment>
  )
}

export default Layout;
